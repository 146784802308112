<template>
  <textarea
      v-bind:id="id"
      class="tinymce-editor"
      v-bind:style="{ minHeight: minHeight }"
      :value="content"
  ></textarea>
</template>

<script>

let lastEditorId = 0;

export default {
  name: "TextEditor",
  props: {
    editorId: String,
    content: String,
    options: Object,
    tools: Object,
    appearance: Object,
    locale: String,
    minHeight: {
      type: String,
      default: '200px',
    },
  },
  data() {
    return {
      objTinymce: null
    }
  },
  computed: {
    id() {
      return this.editorId || `mce-editor-${++lastEditorId}`;
    },
  },
  emits: ["contentChanged"],
  watch: {
    content: function (newValue, oldValue) {
      let component = this;
      if (this.objTinymce && component.value !== this.objTinymce.getContent())  {
        this.objTinymce.setContent(component.value);
      }
    }
  },
  mounted() {
    let component = this;
    let initialOptions = {
      target: this.$el,
      menubar: false,
      plugins: ['autolink', 'codesample', 'link', 'lists', 'media', 'table','image', 'media', 'imagetools', 'visualblocks', 'quickbars', 'codesample', 'help'],
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
      forced_root_block : 'div',
      image_uploadtab: true,
      imagetools_cors_hosts: [ 'website1.rt-framework.test', '192.168.1.66:8080' ],
      // image_advtab: true,
      toolbar_mode: 'sliding',
      // contextmenu: 'link image imagetools table configurepermanentpen',
      // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
      // a11y_advanced_options: true,
      init_instance_callback: function (editor) {
        editor.on('Change KeyUp Undo Redo', function (e) {
          component.$emit('contentChanged', editor.getContent())
        });
        this.objTinymce = editor;
      }
    };

    this.$loadScript('/js/tinymce/tinymce.min.js')
        .then(() => {
          // eslint-disable-next-line no-undef
          tinymce.init(initialOptions).then(()=>{
          // eslint-disable-next-line no-undef
            tinymce.get(this.id).setContent(this.content);
          });
        });
  },
}
</script>

<style scoped>
.tinymce-editor {
  flex: 1;
  display: flex;
}
</style>
